<template>
  <!-- 虚拟仿真实验详情 -->
  <div class="main-contain">
    <div class="head">
      <div class="head-title">问答讨论 / 问答讨论详情</div>
      <div class="returnbt">
        <div class="bt" @click="toback">返回</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="item">
      <div class="title">{{ item.title }}
        <div class="replybt" @click="replybt">回复</div>
      </div>

      <div class="item-two">
        <div v-if="item.is_top === 1" class="item-two-bt item-two-bt-active">
          置顶
        </div>
        <div v-if="item.is_teacher_join === 1" class="item-two-bt">
          老师参与
        </div>
      </div>
      <div class="text-contain">
        <div class="text">
          {{ item.content }}
        </div>
      </div>
      <div class="bottom-contain">
        <div class="bottom-left">
          <el-image class="elimage" :src="item.avatar == null ? image09 : item.avatar" />
          <div class="teach">{{ item.author_name }}</div>
          <div class="time">{{ item.created_at | dataformat}}</div>
          <div class="time">发布</div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right-item">
            <template v-if="item.like_status === 0">
              <el-image class="elimage1" :src="icon2" />
              <div class="num">{{ item.like_count }}</div>
            </template>
            <template v-else-if="item.like_status === 1">
              <el-image class="elimage1" :src="icon5" />
              <div class="num">{{ item.like_count }}</div>
            </template>
          </div>
          <div class="line2"></div>
          <div class="bottom-right-item">
            <el-image class="elimage2" :src="icon1" />
            <div class="num">{{ item.view_count }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="totaltitle">共{{ total }}条回复</div>
    <div class="replycontain">
      <div class="replyitem" v-for="(item, index) in replylist" :key="index">
        <div class="itemup">
          <el-image class="elimage" :src="item.avatar == null ? image09 : item.avatar" />
          <div class="nametext">{{ item.user_name }}</div>
          <div class="timetext">{{ item.created_at | dataformat }}</div>
        </div>
        <div class="itembottom">
          <div class="left">
            <div v-html="item.content"></div>
          </div>
          <div class="right">
            <div class="bottom-right-item" @click="setlike(item)">
              <template v-if="item.like_status === 0">
                <el-image class="elimage1" :src="icon2" />
                <div class="num">{{ item.like_count }}</div>
              </template>
              <template v-else-if="item.like_status === 1">
                <el-image class="elimage1" :src="icon5" />
                <div class="num">{{ item.like_count }}</div>
              </template>
            </div>
            <div class="line2"></div>
            <div class="bottom-right-item" @click="replychildbt(item)">
              <el-image class="elimage3" :src="icon3" />
              <div class="num">{{ item.reply_count }}</div>
            </div>
            <div class="line2" v-if="item.is_mine == 1"></div>
            <div class="bottom-right-item" @click="deletreply(item)" v-if="item.is_mine == 1">
              <el-image class="elimage3" :src="icon4" />
            </div>
          </div>
        </div>
        <div class="replychild">
          <div class="replyitem" v-for="(item, index) in item.child" :key="index">
            <div class="itemup">
              <template v-if="!item.to_user_name">
                <el-image class="elimage" :src="item.avatar == null ? image09 : item.avatar" />
                <div class="nametext">{{ item.user_name }}</div>
              </template>
              <template v-else>
                <el-image class="elimage" :src="item.avatar == null ? image09 : item.avatar" />
                <div class="nametext">{{ item.user_name }}&nbsp;&nbsp;&nbsp;&nbsp;回复&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ item.to_user_name }}</div>
              </template>
              <div class="timetext">
                {{ item.created_at | dataformat }}
              </div>
            </div>
            <div class="itembottom">
              <div class="left">
                <div v-html="item.content"></div>
              </div>
              <div class="right">
                <div class="bottom-right-item" @click="childreply(item)">
                  <el-image class="elimage3" :src="icon3" />
                </div>
                <div class="line2" v-if="item.is_mine == 1"></div>
                <div class="bottom-right-item" @click="deletchildreply(item)" v-if="item.is_mine == 1">
                  <el-image class="elimage3" :src="icon4" />
                </div>
              </div>
            </div>

            <div class="line3"></div>
          </div>
        </div>
        <div class="line3"></div>
      </div>
    </div>
    <Customdialog ref="customdialog" title="发表评论">
      <div slot="dialogbody" class="dialogbody">
        <Tinymce ref="editor" v-model="queryParams.content" style="height: auto; border-radius: 22px"
          @setInput="getValue"></Tinymce>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bt" @click="canclebt">取消</div>
        <div class="bt btactive" @click="submitbt">确定</div>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import Customdialog from "@/components/customdialog.vue";
import {
  getQaReplyList,
  addQaReplyAdd,
  setQaReplyLike,
  QaReplyDelete,
  qareply_deatil
} from "@/api/qareply";
import dayjs from "dayjs";
import Tinymce from "@/components/VueTinymce/index.vue";

export default {
  name: "courselearning",
  components: { Customdialog, Tinymce },
  data() {
    return {
      dialogVisible: false,
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/查看.png"),
      icon2: require("@/assets/coursedetails/点赞.png"),
      icon5: require("@/assets/coursedetails/dzxz.png"),
      icon3: require("@/assets/coursedetails/回复.png"),
      icon4: require("@/assets/shanchu.png"),
      image09: require("@/assets/logo2018.png"),
      item: "",
      replylist: [],
      total: 0,
      queryParams: {
        qa_id: '', // 问答ID
        qa_reply_id: '', // 被回复ID，回复楼层和楼层回复此参数必传
        content: '', // 回复内容
      }
    };
  },
  filters: {
    dataformat: function (value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD");
    },
  },
  created() {
    // let str = decodeURIComponent(this.$route.query.item);
    // this.item = JSON.parse(str);
    // this.queryParams.qa_id = this.item.id
    this.queryParams.qa_id = this.$route.query.id
    this.getqareplylist({ qa_id: this.queryParams.qa_id });
    this.initInfo()
  },
  methods: {
    // 主体详情
    initInfo() {
      qareply_deatil({ id: this.queryParams.qa_id }).then((res) => {
        if (res.code == 0) {
          console.log("resresresresresresresres", res);
          this.item = res.data;
        }
      });
    },
    // 二级回复
    replychildbt(item) {
      this.queryParams.qa_reply_id = item.id
      this.queryParams.content = "";
      this.$refs.customdialog.dialogopenbt();
    },
    getqareplylist(params) {
      this.replylist = [];
      getQaReplyList(params)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.total = response.data.total;
            response.data.data.forEach((item) => {
              this.replylist.push({
                avatar: item.avatar,
                child: item.child,
                content: item.content,
                created_at: item.created_at,
                file: item.file,
                id: item.id,
                like_count: item.like_count,
                is_mine: item.is_mine,
                like_status: item.like_status,
                pid: item.pid,
                qa_id: item.qa_id,
                reply_count: item.reply_count,
                user_name: item.user_name,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toback() {
      this.$router.back(-1)
    },
    replybt() {
      this.$refs.customdialog.dialogopenbt();
      this.queryParams.qa_reply_id = ''
      this.queryParams.content = "";
    },
    // 回复
    childreply(item) {
      this.$refs.customdialog.dialogopenbt();
      this.queryParams.qa_reply_id = item.id;
      this.queryParams.content = "";
    },
    setlike(item) {
      this.setQaReplyLike({
        id: item.id,
      });
    },
    setQaReplyLike(params) {
      setQaReplyLike(params)
        .then((response) => {
          if (response.code == 0) {
            this.getqareplylist({ qa_id: this.item.id });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletreply(item) {
      this.QaReplyDelete({
        id: item.id,
      });
    },
    deletchildreply(item) {
      this.QaReplyDelete({
        id: item.id,
      });
    },
    QaReplyDelete(params) {
      QaReplyDelete(params)
        .then((response) => {
          if (response.code == 0) {
            this.getqareplylist({ qa_id: this.item.id });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    canclebt() {
      this.$refs.customdialog.dialogclosebt();
    },
    submitbt() {
      this.addQaReplyAdd();
      this.$refs.customdialog.dialogclosebt();
    },

    addQaReplyAdd() {
      addQaReplyAdd(this.queryParams)
        .then((response) => {
          if (response.code == 0) {
            this.getqareplylist({ qa_id: this.item.id });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取富文本得值
    getValue(data) {
      this.queryParams.content = data;
    }
  },
};
</script>
<style scoped lang="scss">



.main-contain {
  border: 1px solid transparent;
  margin-bottom: 20px;
  background: #f7f7f7;
  min-height: calc(100vh - 240px);

  .head {
    background: #ffffff;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    height: 40px;
    border-bottom: 2px solid #cccccc;
    padding-bottom: 10px;
    padding-left: 20px;

    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
  }

  .returnbt {
    margin-top: 10px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .bt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  .item {
    padding-top: 20px;
    padding-left: 30px;
    margin-left: 20px;
    min-height: 180px;
    background: #ffffff;

    .title {
      font-size: 20px;
      font-weight: 400;
      color: #222222;

      .replybt {
        margin-right: 10px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
        float: right;
      }
    }



    .item-two {
      margin-top: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item-two-bt {
        margin-right: 10px;
        padding: 2px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 14px;

        font-weight: 400;
        color: #222222;
      }

      .item-two-bt-active {
        border: 1px solid #ca261d;
      }
    }

    .text-contain {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        margin-top: 10px;
        font-size: 15px;

        font-weight: 400;
        color: #222222;
      }

    }

    .bottom-contain {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bottom-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .teach {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .time {
          margin-right: 10px;
          font-size: 12px;

          font-weight: 400;
          color: #a0a0a0;
        }
      }

      .bottom-right {
        padding-right: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bottom-right-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .elimage2 {
            width: 22px;
            height: 16px;
          }

          .elimage1 {
            width: 20px;
            height: 20px;
            margin-bottom: 4px;
          }

          .elimage3 {
            width: 19px;
            height: 16px;
          }

          .num {
            margin-left: 6px;
            font-size: 15px;

            font-weight: 400;
            color: #a0a0a0;
          }
        }

        .line2 {
          margin-left: 20px;
          margin-right: 20px;
          height: 15px;
          border-right: 2px solid #9b9b9b;
        }
      }
    }
  }

  .totaltitle {
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;

    font-weight: 400;
    color: #222222;
  }

  .replycontain {
    min-height: 200px;
    box-sizing: border-box;
    background: #ffffff;
    padding-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;

    .replyitem {
      margin-bottom: 20px;
      margin-left: 40px;

      .itemup {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .nametext {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 15px;
          font-weight: 400;
          color: #222222;
        }

        .timetext {
          font-size: 12px;
          font-weight: 400;
          color: #a0a0a0;
        }
      }

      .itembottom {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .right {
          padding-right: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 45px;

          .bottom-right-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            .elimage1 {
              width: 20px;
              height: 20px;
            }

            .elimage2 {
              width: 19px;
              height: 21px;
              margin-bottom: 4px;
            }

            .elimage3 {
              width: 19px;
              height: 16px;
            }

            .num {
              margin-left: 6px;
              font-size: 15px;

              font-weight: 400;
              color: #a0a0a0;
            }
          }

          .line2 {
            margin-left: 20px;
            margin-right: 20px;
            height: 15px;
            border-right: 2px solid #9b9b9b;
          }
        }
      }

      .line3 {
        margin-top: 15px;
        border-bottom: 1px solid #e5e5e5;
      }

      .replychild {
        margin-right: 20px;
        // background: #f7f7f7;
      }
    }
  }

  .dialogbody {
    padding: 20px;
  }

  .dialogfooter {
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .bt {
      margin-right: 20px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
      user-select: none;
      cursor: pointer;
    }

    .btactive {
      background: #3d84ff;
      color: white;
    }
  }
}
</style>
